import React, { useState, useEffect, useContext } from "react";
import { Bar } from "react-chartjs-2";
import DropDown from "../../common/dropDown";
import { AppContext } from "./../../App";
import { getData } from "../../common/utils";
import SpinnerInfinity from "./../../common/spinner";
import BounceLoader from "react-spinners/BounceLoader";
import { ReactComponent as NoDataSVG } from "./../../assets/noDataSVG.svg";
import t from "./../../assets/noData/type.png";
import NoDataChart from "../../assets/noData/chart.png";
const equipmentDict = JSON.parse(localStorage.getItem("tag_data"));
const exerciseDict = JSON.parse(localStorage.getItem("exercise_data"));

const options = ["Reps", "Sets", "Volume"];

function EquipmentUsageChart(props) {
  const { gym_id, month, year } = props;
  const threshold = 10;
  const [chartData, setChartData] = useState(null);
  const [groupData, setGroupData] = useState(null);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [isLoading, setIsLoading] = useState(true);
  const { appInfo } = useContext(AppContext);
  const   override = { top: '7rem',
    left: '40rem'} ;


  useEffect(() => {
    setGroupData(null);
    setIsLoading(true);
  }, [gym_id, month, year]);

  useEffect(() => {
    fetchData();
  }, [selectedOption, groupData]);

  const handleDropDownChange = (sO) => {
    setSelectedOption(options[sO]);
  };

  const fetchData = async () => {
    try {
      let result = null;

      if (groupData == null) {
        result = await getData(
          `usage?month=${month}&year=${year}&gym_id=${gym_id}&isMonthly=true`,
          appInfo.token
        );
        setGroupData(result);
      } else {
        result = groupData;
      }

      const filteredData = result.filter((item) => {
        const exercise = exerciseDict.find(
          (ex) => ex.exercise_identifier === item.engine_exercise_name
        );
        const equipment = equipmentDict.find(
          (ex) => ex.tag_identifier === item.tag_identifier
        );
        return !(exercise?.pinPlate === "cardio" || exercise?.pinPlate === "interval" || !equipment);
      }).map((item) => {
    
        return {
        equipment: equipmentDict.find(ex => ex.tag_identifier === item.tag_identifier)?.tag_name ?? 'unknown',
        lastMonth: item[`last_month_${selectedOption.toLowerCase()}`],
        currentMonth: item[`current_month_${selectedOption.toLowerCase()}`],
        }
      });

      const groupedData = filteredData.reduce((groups, item) => {
        const { equipment, lastMonth, currentMonth } = item;
        if (!groups[equipment]) {
          groups[equipment] = {
            equipment: item.equipment,
            lastMonth: 0,
            currentMonth: 0,
          };
        }
        groups[equipment] = {
          equipment: item.equipment,
          lastMonth: groups[equipment].lastMonth + (lastMonth ?? 0),
          currentMonth: groups[equipment].currentMonth + (currentMonth ?? 0),
        };
        return groups;
      }, {});

      const labels = Object.keys(groupedData);
      const processedData = Object.values(groupedData);
      setChartData(processedData);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching workouts:", error);
    }
  };

  const data = (equipment, lastMonth, currentMonth) => ({
    labels: ["Last Month", "Current Month"],
    datasets: [
      {
        label: equipment,
        data: [lastMonth, currentMonth],
        backgroundColor: [
          appInfo.org.branding_colors[0],
          appInfo.org.branding_colors[1],
        ],
        borderRadius: [
          {
            topLeft: 0,
            topRight: 10,
            bottomLeft: 0,
            bottomRight: 10,
          },
          {
            topLeft: 0,
            topRight: 10,
            bottomLeft: 0,
            bottomRight: 10,
          },
        ],
        borderSkipped: false,
        barThickness: 21,
        minBarLength:
          currentMonth / lastMonth < 0.1
            ? 1000 * (currentMonth / lastMonth)
            : 0,
        datalabels: {
          color: [appInfo.org.tc1, appInfo.org.tc2],
        },
      },
    ],
  });

  const optionale = {
    responsive: true,
    indexAxis: "y",
    scales: {
      x: {
        grid: {
          drawBorder: false,
          display: false,
        },
        ticks: {
          display: false,
          beginAtZero: true,
        },
      },
      y: {
        afterFit: function (scale) {
          scale.width = 100; //<-- set value as you wish
        },
        grid: {
          drawBorder: false,
          display: false,
        },
        ticks: {
          // mirror: true,
          padding: 5,
          color: ["black", "black"],
          font: {
            style: "italic",
          },
          z: 100,
        },
      },
    },
    plugins: {
      legend: { display: false },
      tooltip: { enabled: false },
      datalabels: {
        color: appInfo.org.tc1,
        anchor: "end",
        align: "start",
        offset: 4,
        formatter: (value, context) => {
          // if (context.dataIndex === 0) {
          //   return context.dataset.data[0];
          // } else if (context.dataIndex === 1 && value < threshold) {
          //   return 'Current Month';
          // } else {
          //   return value;
          // }
          return value;
        },
        display: function (context) {
          return context.dataset.data[context.dataIndex] !== 0;
        },
      },
    },
  };
  function BarChart({ equipment, lastMonth, currentMonth }) {
    return (
      <div>
        <h5 className="equipmentTypeTitle">{equipment}</h5>
        <Bar
          data={data(equipment, lastMonth, currentMonth)}
          height="50%"
          options={optionale}
        />
      </div>
    );
  }
  return (
    <div className="equipmentUsageCard">
      <div className="chartHeader flex justify-between">
        <p className="chartTitle text-gray-600 italic font-semibold">
          Equipment Usage
        </p>
        <DropDown options={options} onSelect={handleDropDownChange} />
      </div>
      <div className="chartRow grid grid-cols-3 gap-10">
        {isLoading ? (
          <BounceLoader
          color={`#${appInfo?.org?.branding_colors[0] ?? "#FC5A02"}`}          loading={isLoading}
          cssOverride={override}
          size={'4rem'}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
        ) : groupData != null && groupData.length > 0 ? (
          chartData.map((data, index) => (
            <div className="chartColumn" key={index}>
              <BarChart {...data} />
            </div>
          ))
        ) : null}
      </div>
      {!isLoading && groupData != null && groupData.length === 0 && (
        <div
          className="w-full flex flex-col justify-center"
          style={{ alignItems: "center" }}
        >
          <img src={NoDataChart} alt="logo" className="h-80" />
          <div className="text-gray-300">No data is available</div>
        </div>
      )}
    </div>
  );
}

export default EquipmentUsageChart;
