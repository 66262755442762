import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import noData from "./../../assets/noData/type.png";
import { addData, getData, updateData } from "../../common/utils";
import { AppContext } from "../../App";
import SpinnerInfinity from "../../common/spinner";
import ImageGallery from "./ImageGallery";
import ReportDetails from "./ReportDetails";

function MaintenanceList({ status, gym_id }) {
  const [openMaintenanceId, setOpenMaintenanceId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [checkedItems, setCheckedItems] = useState([]);
  const [reports, setReports] = useState([]);
  const [comments, setComments] = useState({});
  const [saving, setSaving] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [reporters, setReporters] = useState([]);
  const [tagMapping, setTagMapping] = useState({});
  const [curIndex, setCurIndex] = useState(0);
  const { appInfo } = useContext(AppContext);

  const toggleMaintenance = (id) => {
    setOpenMaintenanceId(openMaintenanceId === id ? null : id);
  };

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleCommentChange = (id, comment) => {
    setComments((prevComments) => ({
      ...prevComments,
      [id]: comment,
    }));
  };

  const handleCheckboxChange = async (id) => {
    setCheckedItems((prevCheckedItems) =>
      prevCheckedItems.includes(id)
        ? prevCheckedItems.filter((item) => item !== id)
        : [...prevCheckedItems, id]
    );
    try {
      const newStatus = status === 1 ? 2 : 1;
      await updateReport(id, { status: newStatus });
      window.location.reload();
    } catch (error) {
      console.error("Error updating report:", error);
    }
  };

  const handleSaveClick = async (id) => {
    try {
      setSaving(true);
      const commentText = comments[id] || "";
      const newComment = {
        text: commentText,
        date_created: {
          _seconds: Math.floor(Date.now() / 1000),
          _nanoseconds: 0,
        },
      };
      setReports((prevReports) =>
        prevReports.map((report) =>
          report.id === id
            ? { ...report, comments: [...report.comments, newComment] }
            : report
        )
      );
      setComments((prevComments) => ({
        ...prevComments,
        [id]: "",
      }));
      const updatedReport = reports.find((r) => r.id === id);
      if (!updatedReport) return;

      await updateReport(id, { comments: [...updatedReport.comments, newComment] });
      await triggerEmail(newComment, updatedReport);
    } catch (error) {
      console.error("Error saving comment:", error);
    } finally {
      setSaving(false);
    }
  };

  const handleDeleteClick = async (id) => {
    try {
      setDeleting(true);
      await updateReport(id, { status: 3 });
      setDeleting(false);
      window.location.reload();
    } catch (error) {
      console.error("Error deleting report:", error);
    }
  };

  const handleDeleteComment = async (reportId, commentIndex) => {
    try {
      const updatedReports = reports.map((report) => {
        if (report.id === reportId) {
          return {
            ...report,
            comments: report.comments.filter((_, index) => index !== commentIndex),
          };
        }
        return report;
      });

      setReports(updatedReports);
      const updatedComments = updatedReports.find((report) => report.id === reportId)?.comments || [];
      await updateReport(reportId, { comments: updatedComments });
    } catch (error) {
      console.error("Error deleting comment:", error);
    } finally {
      setDeleting(false);
    }
  };


  const formatSentTime = (date) => {
    if (!date) return "Invalid Date";
    let parsedDate;
    if (date._seconds) {
      parsedDate = new Date(date._seconds * 1000);
    } else {
      parsedDate = new Date(date);
    }
    if (isNaN(parsedDate.getTime())) return "Invalid Date";
    let hours = parsedDate.getHours();
    let minutes = parsedDate.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    minutes = minutes.toString().padStart(2, "0");
    const day = parsedDate.getDate().toString().padStart(2, "0");
    const month = (parsedDate.getMonth() + 1).toString().padStart(2, "0");
    const year = parsedDate.getFullYear();
    return `${month}.${day}.${year} - ${hours}:${minutes} ${ampm.toLowerCase()}`;
  };

  const fetchReports = async () => {
    try {
      const response = await getData(
        `maintenance?org_id=${appInfo?.org?.org_id}`,
        appInfo.token
      );
      return response;
    } catch (error) {
      console.error("Error fetching reports:", error);
      return [];
    }
  };

  const updateReport = async (report_id, updates) => {
    try {
      const body = JSON.stringify(updates);
      await updateData(`maintenance/${report_id}`, body, appInfo.token);
    } catch (error) {
      throw new Error(error);
    }
  };

  const fetchUsersByRole = async () => {
    try {
      console.log(appInfo);
      const response = await getData(
        `users?org_id=${appInfo?.org?.org_id}`,
        appInfo.token
      );
      const users = response.filter(
        (user) => (user.role_id === 2 || user.role_id === 3) && user.org_id === appInfo?.org?.org_id
      );
      return users;
    } catch (error) {
      console.error("Error fetching users by role:", error);
      return [];
    }
  };

  const fetchReporters = async (reporterId) => {
    try {
      const response = await getData(`gymUsers/${reporterId}`, appInfo.token);
      console.log(response);
      return response;
    } catch (error) {
      console.error(`Error fetching users by reporterId ${reporterId}:`, error);
      return [];
    }
  };

  const triggerEmail = async (newComment, report) => {
    try {
      const users = await fetchUsersByRole();
      const userEmails = users
        .map((user) => user.email)
        .filter((email) => email !== "")
        .join(",");
      let body = {
        templateName: "maintenance_report.hbs",
        data: {
          ticket_number: report.id,
          comment: newComment.comment,
          date_created: formatSentTime(newComment.date_created),
        },
        subject: "An Update Has Been Posted on Ticket",
        toEmail: userEmails,
      };
      body = JSON.stringify(body);
      await addData("emails/triggerEmail", body, appInfo.token);
      console.log("Email sent!");
    } catch (error) {
      console.error("Error sending email", error);
    }
  };
  
  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchReports();
      const filteredReports = data.filter((report) => report.gym_id === gym_id);
      setReports(filteredReports);

      const tagResponse = await getData(`utils/datafile?fileType=tag_data`, appInfo.token);
      const tagMap = tagResponse.reduce((acc, item) => {
        acc[item.tag_identifier] = item.tag_name;
        return acc;
      }, {});
      setTagMapping(tagMap); 
      setIsLoading(false);
    };
    fetchData();
  }, [appInfo?.org?.org_id, gym_id]);

  useEffect(() => {
    if (!isLoading) {
      const filteredData = reports.filter((data) => data.status === 2);
      const initialCheckedItems = filteredData.map((data) => data.id);
      setCheckedItems(initialCheckedItems);

      const fetchAllReporters = async () => {
        const reporterData = await Promise.all(
          reports.map((report) => fetchReporters(report.reporter_id))
        );
        const reportersObject = reporterData.flat().reduce((acc, reporter) => {
          acc[reporter.u_id] = reporter;
          return acc;
        }, {});

        setReporters(reportersObject);
      };
      fetchAllReporters();
    }
  }, [isLoading, reports]);

  const filteredData = reports.filter((data) => data.status === status);
  
  return (
    <>
      {isLoading ? (
        <SpinnerInfinity
          stroke={appInfo.org.color1}
          size={18}
          color={appInfo.org.color2}
          pTop={10}
          pLeft={0}
          className="col-start-2 mt-[10rem] ml-[33rem]"
        />
      ) : (
        <div className="maintainence overflow-hidden sm:rounded-lg w-full col-span-12 mt-2">
          {filteredData.length === 0 ? (
            <div className="flex justify-center items-center h-64 w-full">
              <img
                src={noData}
                alt="No data available"
                className="mx-auto mb-4 max-w-full max-h-full object-contain"
              />
            </div>
          ) : (
            <ul className="divide-y-0">
              {filteredData
              .sort((a, b) => new Date(b.reported_date) - new Date(a.reported_date))
              .map((data) => {
                const equipment_name = tagMapping[data.tag_identifier] || "Unknown Equipment";
                const user = reporters[data.reporter_id] || {
                  name: "Unknown",
                };

                return (
                  <React.Fragment key={data.id}>
                    <div className="px-6 py-2 whitespace-nowrap cursor-pointer flex items-center justify-between bg-white shadow rounded-lg mt-4">
                      <div className="mr-6">
                        <input
                          id={`checkbox-${data.id}`}
                          type="checkbox"
                          checked={checkedItems.includes(data.id)}
                          onChange={() => handleCheckboxChange(data.id)}
                          className="w-4 h-4 border-gray-300 rounded accent-green-600"
                        />
                      </div>
                      <div className="flex items-center flex-1">
                        <div>
                          <p className="text-[20px] font-sans text-gray-700 font-bold">
                            {equipment_name}
                          </p>
                          <p className="text-[16px] font-sans text-gray-500">{data.category}</p>
                        </div>
                        {openMaintenanceId != data.id &&
                          data.images?.length > 0 && (
                            <div className="flex space-x-2 ml-3">
                              {data.images.slice(0, 4).map((img, index) => (
                                <img
                                  key={index}
                                  src={img}
                                  alt={`data.images ${index + 1}`}
                                  className="w-12 h-16 object-cover rounded-md"
                                  onClick={() => setCurIndex(index)}
                                />
                              ))}
                            </div>
                          )}
                      </div>
                      <div className="text-right mr-3">
                        <p className="text-[14px] text-gray-500">
                          {formatSentTime(data.reported_date)}
                        </p>
                      </div>
                      <svg
                        className={`w-6 h-6 transform cursor-pointer ${openMaintenanceId === data.id
                          ? "rotate-180"
                          : "rotate-0"
                          }`}
                        onClick={() => toggleMaintenance(data.id)}
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M19 9l-7 7-7-7"
                        ></path>
                      </svg>
                    </div>

                    {openMaintenanceId === data.id && (
                      <div>
                        <div className="w-full bg-white flex items-start p-4">
                          {/* Left Section: Image or Placeholder */}
                          <div className="w-1/2 flex h-full">
                            <ImageGallery data={data} />
                            {/* Right Section: Description & Reporter */}
                            <ReportDetails data={data} user= {user}/>
                          </div>
                          {/* Comments Section */}
                          <div className="w-1/2 mx-auto h-full min-h-[376px] flex flex-col">
                            <div className="flex-1 bg-stone-50 shadow-md rounded-xl p-4">
                              <div className="flex justify-between items-center">
                                <label className="block text-gray-500 font-sans text-base font-bold mb-2">
                                  Comments
                                </label>
                                {data.comments.length > 2 && (
                                  <button
                                    className="text-black rounded px-2 py-1 text-base mb-4"
                                    onClick={handleOpenModal}
                                  >
                                    See all
                                  </button>
                                )}
                              </div>
                              <div className="flex items-center space-x-2 mb-3">
                                <input
                                  type="text"
                                  className="w-full italic p-2 rounded-md"
                                  placeholder="Add a comment..."
                                  value={comments[data.id] || ""}
                                  onChange={(e) =>
                                    handleCommentChange(data.id, e.target.value)
                                  }
                                ></input>
                                <button
                                  className="px-4 py-1.5 bg-green-500 text-white p-2 rounded-md"
                                  onClick={() => handleSaveClick(data.id)}
                                  disabled={saving}
                                >
                                  Send
                                </button>
                              </div>
                              <div className="mt-4 flex-1">
                                {data.comments.length > 0 ? (
                                  (data.comments.length <= 2
                                    ? data.comments
                                    : data.comments.slice(0, 2)
                                  )
                                    .sort((a, b) => b.date_created._seconds - a.date_created._seconds)
                                    .map((comment, index) => (
                                      <div
                                        key={index}
                                        className="mb-1 p-2 border-gray-200"
                                      >
                                        <div className="text-gray-500 text-sm">
                                          {formatSentTime(comment.date_created)}
                                        </div>
                                        <div className="flex justify-between items-center mb-3">
                                          <div className="text-gray-800  mr-4">
                                            {comment.text}
                                          </div>
                                          <button
                                            className="border-none hover:bg-red-300 rounded-full font-medium text-sm px-1 py-0.5"
                                            onClick={() =>
                                              handleDeleteComment(data.id, index)
                                            }
                                          >
                                            {<FontAwesomeIcon icon={faTrash} />}
                                          </button>
                                        </div>
                                      </div>
                                    ))
                                ) : (
                                  <div className="min-h-[200px] flex items-center justify-center">
                                    <p className="text-gray-300 italic text-base">No comments yet</p>
                                  </div>
                                )}
                                {isModalOpen && (
                                  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                                    <div className="bg-stone-50 min-h-10 max-h-[600px] w-[800px] rounded-lg p-4 shadow-lg overflow-hidden">
                                      <div className="flex justify-between items-center border-b pb-2">
                                        <h2 className="text-base text-gray-500 font-sans font-bold">
                                          All Comments
                                        </h2>
                                        <button
                                          className="text-gray-500 hover:text-gray-800"
                                          onClick={handleCloseModal}
                                        >
                                          ✖
                                        </button>
                                      </div>
                                      <div className="mt-3 max-h-[454px] overflow-y-auto">
                                        {data.comments
                                          .sort((a, b) => b.date_created._seconds - a.date_created._seconds)
                                          .map((comment, index) => (
                                            <div
                                              key={index}
                                              className="mb-1 p-2 border-gray-200"
                                            >
                                              <div className="text-gray-500 text-sm">
                                                {formatSentTime(
                                                  comment.date_created
                                                )}
                                              </div>
                                              <div className="flex justify-between items-center mb-3">
                                                <div className="text-gray-800 mr-2">
                                                  {comment.text}
                                                </div>
                                                <button className="border-none hover:bg-red-300 rounded-full font-medium text-sm px-1 py-0.5"
                                                  onClick={() => handleDeleteComment(data.id, index)}
                                                >
                                                  <FontAwesomeIcon icon={faTrash} />
                                                </button>
                                              </div>
                                            </div>
                                          ))}
                                      </div>
                                      <div className="mt-4 flex justify-between">
                                        <input
                                          type="text"
                                          className="italic w-3/4 p-2 rounded-md"
                                          placeholder="Add a comment..."
                                          value={comments[data.id] || ""}
                                          onChange={(e) =>
                                            handleCommentChange(data.id, e.target.value)
                                          }
                                        ></input>
                                        <button
                                          className="px-4 py-2 w-1/8 bg-green-500 text-white rounded-md text-center hover:bg-green-600"
                                          onClick={() => handleSaveClick(data.id)}
                                          disabled={saving}
                                        >
                                          Send
                                        </button>
                                        <button
                                          className="bg-gray-500 text-white px-4 py-2 p-2 w-1/8 rounded-md hover:bg-gray-700"
                                          onClick={handleCloseModal}
                                        >
                                          Close
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            {/* Delete Report Button */}
                            <div className="flex justify-end mt-4">
                              <button
                                className={`px-4 py-2 ${deleting ? "bg-gray-400 text-black" : "bg-red-500 text-white"} rounded`}
                                onClick={() => handleDeleteClick(data.id)}
                                disabled={deleting}
                              >
                                <FontAwesomeIcon icon={faTrash} /> Delete Report
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                );
              })}
            </ul>
          )}
        </div>
      )}
    </>
  );
}

export default MaintenanceList;
