import React, { useState, useContext, useEffect } from "react";
import MaintenanceList from "./maintenanceList";
import { AppContext } from "../../App";
import { getData } from "../../common/utils";
import Dropdown from "./Dropdown.js";

function Maintenance() {
  const [gyms, setGyms] = useState([]);
  const [selectedGymId, setSelectedGymId] = useState(null);
  const { appInfo } = useContext(AppContext);

  useEffect(() => {
    const fetchGyms = async () => {
      try {
        const org_id = appInfo.org.org_id;
        const gymsData = await getData(`gyms?org_id=${org_id}`, appInfo.token);
        setGyms(gymsData);
        const storedGymId = localStorage.getItem("selectedGymId");

        if (storedGymId && gymsData.some(gym => gym.gym_id === storedGymId)) {
          setSelectedGymId(storedGymId);
        } else {
          setSelectedGymId(gymsData[0]?.gym_id || null);
        }
      } catch (error) {
        console.error("Error fetching gyms:", error);
      }
    };

    fetchGyms();
  }, [appInfo]);

  const handleGymSelection = (index) => {
    const gymId = gyms[index]?.gym_id;
    setSelectedGymId(gymId);
    localStorage.setItem("selectedGymId", gymId);
  };

  return (
    <div className="flex flex-col items-center justify-between py-2 pl-2 pr-10 col-span-12" style={{ height: "50rem" }}>
      <div className="w-full max-w-none">
        <div>
          <ul className="flex px-1 py-1 text-[12.5px] font-sans-bold justify-between items-end">
            <h1 className="text-4xl font-bold text-gray-700 font-sans">Equipment <br />Maintenance Reports</h1>
            <div className="ml-auto">
              <Dropdown
                options={gyms.map((gym) => gym.name)}
                selectedIndex={gyms.findIndex((gym) => gym.gym_id === selectedGymId)}
                onSelect={handleGymSelection}
              />
            </div>
          </ul>
          <section>
            <h2 className="text-[22px] font-bold pl-4 mt-4 font-sans text-yellow-500">Active</h2>
            <div key={selectedGymId}>
              <MaintenanceList status={1} gym_id={selectedGymId} />
            </div>
          </section>
          <section>
            <h2 className="text-[22px] font-bold pl-4 mt-4 font-sans text-green-500">Complete</h2>
            <div key={selectedGymId}>
              <MaintenanceList status={2} gym_id={selectedGymId} />
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Maintenance;
