import React, { useState, useEffect, useRef } from "react";

function Dropdown({ options, selectedIndex, onSelect }) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleSelect = (index) => {
    onSelect(index);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="relative w-32" ref={dropdownRef}>
      <button
        className="w-full bg-white border border-gray-300 px-4 py-2 font-bold text-sm text-blue-600 hover:bg-gray-50 rounded-md shadow-md flex justify-between items-center"
        onClick={() => setIsOpen(!isOpen)}
      >
        {options[selectedIndex] || "Select a Gym"}
        <svg
            className="-mr-1 ml-2 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M10 12a1 1 0 01-.707-.293l-4-4a1 1 0 011.414-1.414L10 9.586l3.293-3.293a1 1 0 111.414 1.414l-4 4A1 1 0 0110 12z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      {isOpen && (
        <ul className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg">
          {options.map((option, index) => (
            <li
              key={index}
              className={`px-4 py-2 cursor-pointer hover:bg-gray-100 hover:text-gray-900 ${
                index === selectedIndex ? "font-semibold text-blue-600" : "font-normal text-gray-700"
              }`}
              onClick={() => handleSelect(index)}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default Dropdown;
