import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";

export default function ImageGallery({ data }) {
    const images = data.images || [];
    const maxImages = 4;

    const [orderedImages, setOrderedImages] = useState(() => {
        const displayImages = [...images];
        while (displayImages.length < maxImages) {
            displayImages.push(null);
        }
        return displayImages;
    });

    const handleImageClick = (index) => {
        if (index === 0 || !orderedImages[index]) return;
        const newImages = [...orderedImages];
        [newImages[0], newImages[index]] = [newImages[index], newImages[0]];
        setOrderedImages(newImages);
    };

    return (
        <div className="flex flex-col items-center">
            {images.length === 0 ? (
                <div className="w-[300px] h-[376px] flex flex-col items-center justify-center rounded-xl bg-stone-50">
                    <FontAwesomeIcon icon={faImage} size="3x" className="text-gray-300" />
                    <p className="text-gray-300 italic text-base mt-2">No images attached</p>
                </div>
            ) : images.length === 1 ? (
                <img
                    src={images[0]}
                    alt="Single Image"
                    className="object-contain rounded-xl border border-black bg-stone-50 w-[300px] h-[376px]"
                />
            ) : (
                <>
                    {orderedImages[0] ? (
                        <img
                            src={orderedImages[0]}
                            alt="Main Display"
                            className="object-contain rounded-xl border border-black bg-stone-50 w-64 h-[280px]"
                        />
                    ) : (
                        <div className="flex items-center justify-center rounded-xl border border-black bg-stone-50 w-64 h-[280px]">
                            <FontAwesomeIcon icon={faImage} size="3x" className="text-gray-300" />
                            <p className="text-gray-300 italic text-base mt-2">No image</p>
                        </div>
                    )}

                    <div className="flex space-x-2 pt-2">
                        {orderedImages.slice(1).map((img, index) => (
                            <div key={index} className="cursor-pointer" onClick={() => handleImageClick(index + 1)}>
                                {img ? (
                                    <img
                                        src={img}
                                        alt={`Thumbnail ${index + 1}`}
                                        className="object-contain rounded-xl border border-black bg-stone-50 w-16 h-24"
                                    />
                                ) : (
                                    <div className="flex items-center justify-center rounded-xl border border-black bg-stone-50 w-16 h-24">
                                        <FontAwesomeIcon icon={faImage} size="2x" className="text-gray-300" />
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
}
