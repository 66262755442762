import { useEffect, useState, useContext } from "react";
import default_img from "../../assets/noData/UserPic.jpg";
import { AppContext } from "../../App";
import { getData } from "../../common/utils";

export default function ReportDetails({ data, user }) {
    const { appInfo } = useContext(AppContext);
    const [userImage, setUserImage] = useState(default_img); 

    useEffect(() => {
        if (user?.profile_pic) {
            setUserImage(user.profile_pic);
        } else {
            getData(`utils/profile-image/${data.user_id}`, appInfo.token)
            .then((result) => {
                if (result.profileImageUrl) {
                    setUserImage(result.profileImageUrl);
                }
            })
            .catch((error) => {
                console.error("Error fetching profile image:", error);
                setUserImage(default_img);
            });
        }
    }, [user?.profile_pic]);

    return (
        <div className="ml-10 mr-14 w-2/3 break-words space-y-16">
            <div>
                <label className="block text-gray-500 font-sans text-base font-bold mb-2">Category</label>
                <p className="text-gray-900 font-sans">{data.category}</p>
            </div>

            <div>
                <label className="block text-gray-500 font-sans text-base font-bold mb-2">Description</label>
                <p className="text-gray-900 font-sans">{data.description}</p>
            </div>

            <div>
                <label className="block text-gray-500 font-sans text-base font-bold mb-2">Reported by:</label>
                <div className="flex items-start space-x-5">
                    <img
                        className="w-16 h-16 rounded-full object-cover"
                        src={userImage}
                        alt={`${user.name}'s profile pic`} 
                    />
                    <div>
                        <p className="font-bold font-sans text-gray-900">{user.name}</p>
                        <p className="text-sm font-sans text-gray-900">@{user.display_name || user.name.replace(/\s+/g, "").toLowerCase()}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}