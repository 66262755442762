import React from "react";
import Maintenance from "./maintenance";

function MaintenanceComponent() {
  return <Maintenance />;
}
const maintenanceModule = {
  routeProps: {
    path: "/maintenance",
    element: <MaintenanceComponent />,
  },
  name: "Maintenance",
};

export default maintenanceModule;

