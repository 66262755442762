import React, { useContext, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { IoIosLogOut } from "react-icons/io";
import logo from "../assets/beta_logo.png";
import { AppContext } from "./../App";
import SignOut from "./../authentication/signOut";
import { useNavigate } from "react-router-dom";
import a from "../assets/Button UI files/analytics_icon_selected.png";
import aI from "../assets/Button UI files/analytics_icon_unselected.png";
import v from "../assets/Button UI files/videos_icon_selected.png";
import vI from "../assets/Button UI files/videos_icon_unselected.png";
import c from "../assets/Button UI files/communicate_icon_selected.png";
import cI from "../assets/Button UI files/communicate_icon_unselected.png";
import s from "../assets/Button UI files/settings_icon_selected.png";
import sI from "../assets/Button UI files/settings_icon_unselected.png";
import t from "../assets/Button UI files/trainers_icon_selected.png";
import tI from "../assets/Button UI files/trainers_icon_unselected.png";
import m from "../assets/Button UI files/maintenance_fill.png";
import mI from "../assets/Button UI files/maintenance_nofill.png";

const menuItems = {
  0: [
    {
      title: "Analytics",
      path: "/analytics",
      icon: aI,
      iconSelected: a,
      cName: "nav-text",
    },
    {
      title: "Videos",
      path: "/videos",
      icon: vI,
      iconSelected: v,
      cName: "nav-text",
    },
    {
      title: "Communicate",
      path: "/communication",
      icon: cI,
      iconSelected: c,
      cName: "nav-text",
    },
    {
      title: "Trainers",
      path: "/trainers",
      icon: tI,
      iconSelected: t,
      cName: "nav-text",
    },
    {
      title: "Settings",
      path: "/settings",
      icon: sI,
      iconSelected: s,
      cName: "nav-text",
    },
    {
      title: "Maintenance",
      path: "/maintenance",
      icon: mI,
      iconSelected: m,
      cName: "nav-text",
    },
  ],
  1: [
    {
      title: "Settings",
      path: "/settings",
      icon: sI,
      iconSelected: s,
      cName: "nav-text",
    },
  ],
  2: [
    {
      title: "Analytics",
      path: "/analytics",
      icon: aI,
      iconSelected: a,
      cName: "nav-text",
    },
    {
      title: "Videos",
      path: "/videos",
      icon: vI,
      iconSelected: v,
      cName: "nav-text",
    },
    {
      title: "Communicate",
      path: "/communication",
      icon: cI,
      iconSelected: c,
      cName: "nav-text",
    },
    {
      title: "Trainers",
      path: "/trainers",
      icon: tI,
      iconSelected: t,
      cName: "nav-text",
    },
    {
      title: "Settings",
      path: "/settings",
      icon: sI,
      iconSelected: s,
      cName: "nav-text",
    },
    {
      title: "Maintenance",
      path: "/maintenance",
      icon: mI,
      iconSelected: m,
      cName: "nav-text",
    },
  ],
  3: [
    {
      title: "Analytics",
      path: "/analytics",
      icon: aI,
      iconSelected: a,
      cName: "nav-text",
    },
    {
      title: "Videos",
      path: "/videos",
      icon: vI,
      iconSelected: v,
      cName: "nav-text",
    },
    {
      title: "Communicate",
      path: "/communication",
      icon: cI,
      iconSelected: c,
      cName: "nav-text",
    },
    {
      title: "Trainers",
      path: "/trainers",
      icon: tI,
      iconSelected: t,
      cName: "nav-text",
    },
    {
      title: "Settings",
      path: "/settings",
      icon: sI,
      iconSelected: s,
      cName: "nav-text",
    },
    {
      title: "Maintenance",
      path: "/maintenance",
      icon: mI,
      iconSelected: m,
      cName: "nav-text",
    },
  ],
  4: [
    {
      title: "Videos",
      path: "/videos",
      icon: vI,
      iconSelected: v,
      cName: "nav-text",
    },
    {
      title: "Settings",
      path: "/settings",
      icon: sI,
      iconSelected: s,
      cName: "nav-text",
    },
  ],
};

function SideBar() {
  const navigate = useNavigate();

  const { appInfo, setAppInfo } = useContext(AppContext);
  const handleLogout = () => {
    SignOut(navigate, setAppInfo);
  };
  const userRole = appInfo?.userInfo?.role_id ?? 0;
  return (
    <nav className="fixed" style={{ height: "98%" }}>
      <div className="flex flex-col bg-white text-white shadow w-20 rounded-lg h-full">
        <div className="flex flex-col items-center py-4 space-y-4 border-b-8 border-gray-100">
          <img src={logo} alt="Logo" className="w-16 h-16 object-cover" />
        </div>
        <div className="flex flex-col  space-y-2">
          {menuItems[userRole].map((item, index) => (
            <NavLink
              to={item.path}
              key={index}
              className={({ isActive }) =>
                isActive
                  ? "active flex flex-col items-center  w-[90%] rounded-lg h-16 pt-2 bg-purple-100 ml-1 mt-1 mr-1"
                  : "inactive flex flex-col items-center w-[90%] rounded-lg h-16 pt-2 hover:bg-transparent  ml-1 mt-1 mr-1"
              }
            >
              <img
                src={
                  window.location.pathname.includes(item.path)
                    ? item.iconSelected
                    : item.icon
                }
                alt="Icon"
                className="w-6 h-6"
              />
              <span className="mt-1 text-xs font-semibold text-purple-800">
                {item.title}
              </span>
            </NavLink>
          ))}
        </div>
        <div className="flex flex-col  items-center fixed bottom-1 text-black hover:bg-purple-100">
          <button
            onClick={handleLogout}
            className=" mx-1 rounded-lg h-16 pt-2 w-[4.5rem] mb-6"
          >
            <IoIosLogOut className="ml-6" size={24} />
            <span className="text-if mt-1">Logout</span>
          </button>
        </div>
      </div>
    </nav>
  );
}

export default SideBar;
