import analyticsModule from './Analytics';
import communicationModule from './Communication';
import settingsModule from './Settings';
import trainersModule from './Trainers';
import VideosModule from './Videos';
import maintenanceModule from "./Maintenance";

const expModules = {
  0: [analyticsModule,
    communicationModule,
    settingsModule,
    trainersModule,
    VideosModule,
    maintenanceModule,],
  1: [settingsModule],
  2: [analyticsModule,
      communicationModule,
      settingsModule,
      trainersModule,
      VideosModule,
      maintenanceModule],
  3: [  analyticsModule,
        communicationModule,
        settingsModule,
        trainersModule,
        VideosModule,
        maintenanceModule,],
  4: [
      settingsModule,
      VideosModule]
}

export default expModules;
